import { BaseOptions, LocalizedBaseOptions } from "./form.types";
import { FormSpec, SomeInputSpec, localized } from "../../form-specs";
import { FormWidgetSpec, Widget } from "../";
import { getBaseOptionsSpecs, localizedOptions } from "./";
import { getFormFieldVariable, getFormFieldsDescription, isFormFieldRequired } from "./form.util";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { WidgetGroup } from "../widget.enum";
import namespaceList from "../../i18n/namespaceList";
import { readOnlyField } from "../../components/utils";

interface LocalizedWidgetOptions extends LocalizedBaseOptions {
    placeholder: string;
}

interface WidgetOptions extends BaseOptions<LocalizedWidgetOptions> {
    enableReadOnly?: boolean;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "form-email-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginForm, "emailWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginForm, "emailWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getBaseOptionsSpecs("email"),
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "placeholder",
                                        label: getI18nLocaleObject(namespaceList.pluginForm, "placeholder"),
                                        type: "text",
                                    },
                                ],
                            }),
                            readOnlyField("enableReadOnly"),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const emailWidget: FormWidgetSpec<WidgetOptions> = {
    id: "form-email",
    type: "form",
    widgetGroup: WidgetGroup ? WidgetGroup.INPUT : 6,
    name: getI18nLocaleObject(namespaceList.pluginForm, "emailWidget"),
    description: getI18nLocaleObject(namespaceList.pluginForm, "emailWidgetDesc"),
    /* jscpd:ignore-start */
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        fieldId: "",
        required: false,
        localized: [],
        dynamicFieldId: "",
        enableReadOnly: false,
    }),
    async toInputSpec(widget: Widget<WidgetOptions>, context: CMSProvidedProperties): Promise<SomeInputSpec<any, any>> {
        const localeOptions = localizedOptions(context, widget.options) || { label: "", default: "" };
        const { enableReadOnly } = widget.options;
        return {
            type: "email",
            variable: getFormFieldVariable(widget.options),
            label: localeOptions.label,
            default: localeOptions.default,
            placeholder: (localeOptions as LocalizedWidgetOptions).placeholder,
            required: await isFormFieldRequired(widget.options, context),
            message: (localeOptions as LocalizedWidgetOptions).message,
            options: widget.options,
            enableReadOnly,
        };
    },
    /* jscpd:ignore-end */
    instanceDescription({ widget }): string {
        const formFrieldDescriptionParams = {
            /* jscpd:ignore-start */
            fieldId: widget.options.fieldId,
            dynamicFieldId: widget.options.dynamicFieldId,
            useforBookingsModule: widget.options.useforBookingsModule,
            defaultDescription: getI18nLocaleString(namespaceList.pluginForm, "emailWidgetDesc"),
            /* jscpd:ignore-end */
        };
        return getFormFieldsDescription(formFrieldDescriptionParams);
    },
};
